//@ts-ignore
import styled from "./MenuBusinessSection.module.css";
//@ts-ignore
import { getUserPermissions, SelectWrapper } from "@viuti/recursos";
import { businessList } from "./consts";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BurgerIcon, LogoApp } from "../../models/icons";

const MenuBusinessSection = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const userPermissions = getUserPermissions();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const navigate = useNavigate();
  return (
    <section className={styled.menuBusiness__section}>
      {screenWidth >= 900 && (
        <SelectWrapper
          selectItems={businessList(navigate)}
          selectId={"resources-menuWrapper-button-burgerIcon"}
          testId="resources-menuWrapper-select-businessList"
        >
          <img
            src={BurgerIcon}
            width={30}
            height={30}
            alt="burger icon"
            id="#first-step"
          />
        </SelectWrapper>
      )}
      <a href={userPermissions.startView}>
        <img src={LogoApp} height={35} alt="Prikly logo" id="welcome-step" />
      </a>
    </section>
  );
};

export default MenuBusinessSection;
