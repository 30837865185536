const base = "https://gw.zymple.io";

export const accountBaseUrl = `${base}/cuenta/v1`;

const tokenLocal = localStorage.getItem("access_token");

export const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${tokenLocal}`,
  },
};
