//@ts-ignore
import styled from "./styles/FooterButton.module.css";
// @ts-ignore
import { Icon } from "@viuti/recursos";
import { IFooterButton } from "../../models/interfaces/FooterNavigation";
import ValidateNavigation from "../ValidateNavigation/ValidateNavigation";

const FooterButton = ({
  icon,
  label,
  accessId,
  redirectTo,
  isMore = false,
}: IFooterButton) => {
  const currentPath = window.location.pathname;

  if (currentPath.includes(redirectTo) && !isMore) {
    return (
      <div className={styled.footerBttn__selected}>
        <span>
          <Icon path={icon} color={"#1a0063"} size={32} alt={label} />
        </span>
        {/* <p>{label}</p> */}
      </div>
    );
  }
  return (
    <ValidateNavigation
      href={redirectTo}
      accessId={accessId}
      customClassName={styled.footerBttn}
    >
      <span>
        <Icon path={icon} color={"#868585"} size={24} alt={label} />
      </span>
      {/* <p>{label}</p> */}
    </ValidateNavigation>
  );
};

export default FooterButton;
