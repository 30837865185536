//@ts-ignore
import MenuWrapper from "../../components/MenuWrapper/MenuWrapper";
//@ts-ignore
// import style from "./styles/Menu.module.css";

const Menu = () => {
  return <MenuWrapper isModalShow={false} modalProps={{}} />;
};

export default Menu;
