// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*! NO BORRAR // DON'T REMOVE*/
html > body > main {
  display: grid;
  grid-template-rows: 82px 1fr;
  height: 100%;
}

[id^="single-spa-application"] {
  overflow: auto;
}

[id^="single-spa-application:@viuti/navigation"],
[id^="single-spa-application:@viuti/seguridad"] {
  overflow: visible;
}

#viuti-front-mainContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  max-width: 1462px;
  height: 100%;
  padding: 24px 64px 32px;
  overflow: hidden;
}

@media screen and (width <= 900px) {
  #viuti-front-mainContent {
    padding: 20px 24px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Layout.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;EACE,aAAa;EACb,4BAA4B;EAC5B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,YAAY;EACZ,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":["/*! NO BORRAR // DON'T REMOVE*/\nhtml > body > main {\n  display: grid;\n  grid-template-rows: 82px 1fr;\n  height: 100%;\n}\n\n[id^=\"single-spa-application\"] {\n  overflow: auto;\n}\n\n[id^=\"single-spa-application:@viuti/navigation\"],\n[id^=\"single-spa-application:@viuti/seguridad\"] {\n  overflow: visible;\n}\n\n#viuti-front-mainContent {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  margin: auto;\n  max-width: 1462px;\n  height: 100%;\n  padding: 24px 64px 32px;\n  overflow: hidden;\n}\n\n@media screen and (width <= 900px) {\n  #viuti-front-mainContent {\n    padding: 20px 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
