// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AfsfR4604GLbv81x9Uwj {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 450px;
}

.Qd6KuENuZFXrrleJIpLm {
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.Qd6KuENuZFXrrleJIpLm:hover {
  transform: scale(1.1);
}

.PJOQ0q7Mwpi0ntlzQZsD {
  text-align: center;
  color: #1f1f1f;
  margin-bottom: 2rem;
  font-size: 1rem;
  line-height: 1.5;
}

.YsAwcOQZNmU75mDNRa16 {
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: center;
}

.YsAwcOQZNmU75mDNRa16 > button {
  min-width: 120px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModalPlanStatus/ModalPlanStatus.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".modal__plan__status {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  max-width: 450px;\n}\n\n.modal__plan__status__icon {\n  margin-bottom: 1.5rem;\n  padding: 1rem;\n  background-color: #f8f9fa;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transition: transform 0.3s ease;\n}\n\n.modal__plan__status__icon:hover {\n  transform: scale(1.1);\n}\n\n.modal__plan__status__description {\n  text-align: center;\n  color: #1f1f1f;\n  margin-bottom: 2rem;\n  font-size: 1rem;\n  line-height: 1.5;\n}\n\n.modal__plan__status__buttons {\n  display: flex;\n  gap: 1rem;\n  width: 100%;\n  justify-content: center;\n}\n\n.modal__plan__status__buttons > button {\n  min-width: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal__plan__status": `AfsfR4604GLbv81x9Uwj`,
	"modal__plan__status__icon": `Qd6KuENuZFXrrleJIpLm`,
	"modal__plan__status__description": `PJOQ0q7Mwpi0ntlzQZsD`,
	"modal__plan__status__buttons": `YsAwcOQZNmU75mDNRa16`
};
export default ___CSS_LOADER_EXPORT___;
