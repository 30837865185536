//@ts-ignore
import styled from "./style/Profile.module.css";
import React, { useEffect, useRef, useState } from "react";
import {
  EmployeeHasNotPicture,
  EmployeeHasPicture,
} from "./views/EmployeePicture";
import UserMenuList from "./components/menuList";
import { IAccountInfo } from "../../models/interfaces/account";

const Profile = ({ width }) => {
  const userProfileBttn = useRef(null);
  const menuContainerRef = useRef(null);
  const sidebarOverlay = useRef(null);
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);

  const handleToggleMenu = () => {
    setIsUserMenuVisible(!isUserMenuVisible);
    menuContainerRef.current?.classList.toggle(
      styled.profile__menuList__visible
    );
    sidebarOverlay.current?.classList.toggle(styled.sidebar__overlay__visible);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        !menuContainerRef.current.contains(event.target) &&
        !userProfileBttn.current.contains(event.target)
      ) {
        setIsUserMenuVisible(false);
        menuContainerRef.current?.classList.remove(
          styled.profile__menuList__visible
        );
        sidebarOverlay.current?.classList.remove(
          styled.sidebar__overlay__visible
        );
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const account_info: IAccountInfo = JSON.parse(
    localStorage.getItem("account_info")
  );

  return (
    <>
      <button
        className={styled.profile}
        onClick={handleToggleMenu}
        ref={userProfileBttn}
      >
        {width >= 600 && (
          <div className={styled.profile__info}>
            <p className={styled.profile__nikename}>
              {account_info.lounge.name}
            </p>
          </div>
        )}
        {account_info.employee.profileImage ? (
          <EmployeeHasPicture
            imgUrl={account_info.employee.profileImage}
            name={account_info.employee.name}
            surname={account_info.employee.lastName}
          />
        ) : (
          <EmployeeHasNotPicture
            name={account_info.employee.name}
            surname={account_info.employee.lastName}
          />
        )}
      </button>
      <div className={styled.sidebar__overlay} ref={sidebarOverlay}></div>
      <article className={styled.profile__menuList} ref={menuContainerRef}>
        <UserMenuList handleToggleMenu={handleToggleMenu} />
      </article>
    </>
  );
};

export default Profile;
