import React, { useEffect, useState } from "react";
import {
  ModalAcceptCancel as ModalComponent,
  ButtonWithoutIcon,
  //@ts-ignore
} from "@viuti/recursos";
import { HiMiniStar, HiClock, HiXCircle, HiCheck } from "react-icons/hi2";
// @ts-ignore
import style from "./ModalPlanStatus.module.css";
import {
  checkPlanStatus,
  IPlanStatus,
  PlanStatusType,
} from "@Adapters/CheckPlanStatus.adapter";
import {
  shouldShowNotification,
  updateNotificationStatus,
} from "@Services/DegradedPlanNotification.service";

const ModalPlanStatus = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [planStatus, setPlanStatus] = useState<IPlanStatus | null>(null);

  useEffect(() => {
    const verifyPlanStatus = async () => {
      if (shouldShowNotification()) {
        const response = await checkPlanStatus();
        if (response.isSuccessful) {
          updateNotificationStatus();
          if (response.data.type !== PlanStatusType.OK) {
            setPlanStatus(response.data);
            setIsModalVisible(true);
          }
        }
      }
    };

    const currentPath = window.location.pathname.split("/")[1];
    const excludedRoutes = ["", "onboarding", "recuperar"];

    if (!excludedRoutes.includes(currentPath)) {
      verifyPlanStatus();
    }
  }, []);

  const getModalContent = () => {
    if (!planStatus) return null;
    const statusIconDictionary = {
      [PlanStatusType.OK]: (
        <HiCheck size={48} color="#20d66f" strokeWidth={2} />
      ),
      [PlanStatusType.VALID]: <HiClock size={48} color="#f8d308" />,
      [PlanStatusType.GRACE_PERIOD]: <HiXCircle size={48} color="#dc3545" />,
    };
    const statusIcon = statusIconDictionary[planStatus.type];

    return (
      <div className={style.modal__plan__status}>
        <div className={style.modal__plan__status__icon}>{statusIcon}</div>
        <p className={style.modal__plan__status__description}>
          {planStatus.message}
        </p>
        <div className={style.modal__plan__status__buttons}>
          <ButtonWithoutIcon
            textBttn="Entendido"
            handleClick={() => setIsModalVisible(false)}
          />
          <ButtonWithoutIcon
            isPrimary
            textBttn="Actualizar pago"
            handleClick={() => window.location.replace("/mi-cuenta/planes")}
          />
        </div>
      </div>
    );
  };

  return (
    <ModalComponent
      title="Estado del Plan"
      visibility={{
        isVisible: isModalVisible,
        isSetVisible: setIsModalVisible,
      }}
      actionButtonClose={() => setIsModalVisible(false)}
      showButtons={{
        showButtonOne: false,
        showButtonTwo: false,
        showButtonClose: true,
      }}
    >
      {getModalContent()}
    </ModalComponent>
  );
};

export default ModalPlanStatus;
