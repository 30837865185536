import { sendRolLimitAccessEmail_service } from "../services/SendRolLimitAccessEmail.service";

export const sendRolLimitAccessEmail = async (requiredUuidModule: string) => {
  const payload = {
    uuIdModulo: requiredUuidModule,
  };

  const response = await sendRolLimitAccessEmail_service(payload);

  return response;
};
