import React from "react";
import { validateAccess } from "../../utilities/validateNavigation";

interface IValidateNavigation {
  href: string;
  accessId: string;
  customClassName?: any;
  children: React.ReactNode;
}

const ValidateNavigation = ({
  href,
  accessId,
  customClassName,
  children,
}: IValidateNavigation) => {
  return (
    <button
      // onClick={() => validateAccess(href, accessId)}
      onClick={() => (window.location.href = href)}
      className={customClassName}
    >
      {children}
    </button>
  );
};

export default ValidateNavigation;
