import axios from "axios";
import { accountBaseUrl, config } from "./constGlobals";

export interface IPlanStatusResponse {
  isSuccessful: boolean;
  status: number;
  message: string;
  data: {
    type: number;
    days: number;
    message: string;
  };
}

export const checkPlanStatus_service =
  async (): Promise<IPlanStatusResponse> => {
    const url = `${accountBaseUrl}/Account/PlanSuscriptionStatus`;

    try {
      const response = await axios.get(url, config);
      return {
        isSuccessful: true,
        ...response.data,
      };
    } catch (error) {
      return {
        isSuccessful: false,
        status: error?.response?.status || 500,
        message:
          error?.response?.data?.message ||
          "Ocurrió un error al verificar el estado del plan.",
        data: {
          type: 0,
          days: 0,
          message: "Ocurrió un error al verificar el estado del plan.",
        },
      };
    }
  };
