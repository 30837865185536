import axios from "axios";
import { accountBaseUrl, config } from "./constGlobals";

export const sendRolLimitAccessEmail_service = async (payload: {
  uuIdModulo: string;
}) => {
  const url = `${accountBaseUrl}/Roles/SendMail`;

  try {
    const response: IResponse = await axios
      .post(url, payload, config)
      .then((res) => res.data);
    return {
      isSuccessful: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Ocurrió un error al enviar el correo. Por favor, intenta de nuevo.",
      data: "Ocurrió un error al enviar el correo. Por favor, intenta de nuevo.",
    };
  }
};
