import { IAccountInfo } from "@Models/interfaces/account";
import React, { useState } from "react";
import { HiLockClosed, HiInbox } from "react-icons/hi2";

// @ts-ignore
import style from "../../ModalAccessDenied.module.css";
//@ts-ignore
import { ButtonWithoutIcon } from "@viuti/recursos";
import { sendRolLimitAccessEmail } from "@Adapters/SendRolLimitAccessEmail.adapter";

interface IAccessDeniedProps {
  accountInfo: IAccountInfo;
  requiredAccess: string;
  emailSentResponse: IResponse;
  setEmailSentResponse: (response: IResponse) => void;
}

const AccessDenied = ({
  accountInfo,
  requiredAccess,
  emailSentResponse,
  setEmailSentResponse,
}: IAccessDeniedProps) => {
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const handleRequestAccess = async () => {
    setIsSendingEmail(true);
    const response: IResponse = await sendRolLimitAccessEmail(requiredAccess);

    setEmailSentResponse(response);
    setIsSendingEmail(false);
  };

  return (
    <div className={style.modal__access__denied}>
      {emailSentResponse !== null ? (
        <>
          <div
            className={`${style.modal__access__denied__icon__container} 
            ${
              emailSentResponse.isSuccessful
                ? style.modal__access__denied__icon__success__container
                : style.modal__access__denied__icon__error__container
            }
          `}
          >
            <span
              className={`${style.modal__access__denied__icon}
              ${
                emailSentResponse.isSuccessful
                  ? style.modal__access__denied__icon__success
                  : style.modal__access__denied__icon__error
              }
            `}
            >
              <HiInbox
                size={48}
                color={emailSentResponse.isSuccessful ? "#7cd588" : "#d57c7c"}
              />
            </span>
          </div>
          <p className={style.modal__access__denied__description}>
            {emailSentResponse.data}
          </p>
          {!emailSentResponse.isSuccessful && (
            <ButtonWithoutIcon
              textBttn="Reintentar"
              handleClick={handleRequestAccess}
              isLoading={isSendingEmail}
              isDisabled={isSendingEmail}
            />
          )}
        </>
      ) : (
        <InitialChildren
          accountInfo={accountInfo}
          handleRequestAccess={handleRequestAccess}
          isSendingEmail={isSendingEmail}
        />
      )}
    </div>
  );
};

const InitialChildren = ({
  accountInfo,
  handleRequestAccess,
  isSendingEmail,
}: {
  accountInfo: IAccountInfo;
  handleRequestAccess: () => void;
  isSendingEmail: boolean;
}) => {
  return (
    <>
      <h2 className={style.modal__access__denied__title}>
        Lo sentimos, no tienes permisos para acceder a esta sección.
      </h2>
      <div className={style.modal__access__denied__icon__container}>
        <span className={style.modal__access__denied__icon}>
          <HiLockClosed size={48} color="#937cf4" />
        </span>
      </div>
      <p className={style.modal__access__denied__description}>
        Tu rol como <b>{accountInfo.role.name}</b> indica que no tienes acceso a
        la función que intentas acceder. Contacta a tu administrador para
        obtener más información.
      </p>
      <ButtonWithoutIcon
        textBttn="Solicitar acceso"
        handleClick={handleRequestAccess}
        isLoading={isSendingEmail}
        isDisabled={isSendingEmail}
      />
    </>
  );
};

export default AccessDenied;
