// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DLnmCE_UgV8JOQZX7KYk {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.CDxv0LZESdg2KmqBLUEX {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.CDxv0LZESdg2KmqBLUEX:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.mBzyhuDCnhz30pR9R0Vm {
  display: flex;
  align-items: center;
  justify-content: center;
}

.UJlTDDmURNGPpDAEOy8Z {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 8px;
}

.mBzyhuDCnhz30pR9R0Vm span {
  font-size: 0.875rem;
  color: #333;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/menuUserActionSection/MenuUserActionSection.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,eAAe;EACf,uBAAuB;EACvB,kBAAkB;EAClB,sCAAsC;AACxC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".menuUserActionSection__article {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n}\n\n.whatsappButton {\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n  padding: 0.5rem 0.75rem;\n  border-radius: 8px;\n  transition: background-color 0.3s ease;\n}\n\n.whatsappButton:hover {\n  background-color: rgba(0, 0, 0, 0.05);\n}\n\n.whatsappButtonContent {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.whatsappIcon {\n  width: 1.5rem;\n  height: 1.5rem;\n  margin-right: 8px;\n}\n\n.whatsappButtonContent span {\n  font-size: 0.875rem;\n  color: #333;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuUserActionSection__article": `DLnmCE_UgV8JOQZX7KYk`,
	"whatsappButton": `CDxv0LZESdg2KmqBLUEX`,
	"whatsappButtonContent": `mBzyhuDCnhz30pR9R0Vm`,
	"whatsappIcon": `UJlTDDmURNGPpDAEOy8Z`
};
export default ___CSS_LOADER_EXPORT___;
