export const qaDomain = "https://app.zymple.io";
export const iconPath = `${qaDomain}/recursos/icons`;

// routes: MenuWrapper
export const BILLING_ROUTE = "/facturacion";
export const ACCOUNT_ROUTE = "/mi-cuenta";
export const TEAM_ROUTE = "/empleados";
export const PRODUCTS_ROUTE = "/almacen";
export const LOUNGES_ROUTE = "/locales";
export const SUPPLIERS_ROUTE = "/proveedores";
export const REPORTS_ROUTE = "/reportes";
export const BOOKING_ROUTE = "/reservas";
export const TAXES_ROUTE = "/impuestos";
export const SERVICES_ROUTE = "/servicios";
export const CASH_REGISTER = "/cajas";
export const CLIENTS_ROUTE = "/clientes";
export const STATISTICS_ROUTE = "/estadisticas";
export const SECURITY_ROUTE = "/roles";
export const MARKETING_ROUTE = "/marketing";
export const COMMISSIONS_ROUTE = "/comisiones";
