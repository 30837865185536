import {
  CashRegisterStrokeIcon,
  PeopleIcon,
  SiteIcon,
} from "../../models/icons";
import {
  BOOKING_ROUTE,
  CASH_REGISTER,
  CLIENTS_ROUTE,
  STATISTICS_ROUTE,
  iconPath,
} from "../../models/localPath";
import { validateAccess } from "../../utilities/validateNavigation";

export const businessList = (navigate) => [
  {
    itemLabel: "Caja",
    itemSvg: CashRegisterStrokeIcon,
    onItemSelected: () => validateAccess(CASH_REGISTER, ""),
  },
  {
    itemLabel: "Reservas",
    itemSvg: `${iconPath}/configMenu/calendar.svg`,
    onItemSelected: () => validateAccess(BOOKING_ROUTE, ""),
  },
  {
    itemLabel: "Clientes",
    itemSvg: PeopleIcon,
    onItemSelected: () => validateAccess(CLIENTS_ROUTE, ""),
  },
  {
    itemLabel: "Estadísticas",
    itemSvg: SiteIcon,
    onItemSelected: () => validateAccess(STATISTICS_ROUTE, ""),
  },
];
