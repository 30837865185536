import { Route, Routes } from "react-router-dom";
import Menu from "../pages/navigation/MenuWrapper";
//@ts-ignore
import { ProtectedRoute } from "@viuti/recursos";

const ListContextRoutes = () => {
  return (
    <Routes>
      <Route path="/cajas/*" element={<Menu />}></Route>
      <Route path="/estadisticas/*" element={<Menu />}></Route>
      <Route path="/reservas/*" element={<Menu />} />
      <Route path="/clientes/*" element={<Menu />}></Route>
      <Route path="/empleados/*" element={<Menu />}></Route>
      <Route path="/servicios/*" element={<Menu />}></Route>
      <Route path="/almacen/*" element={<Menu />}></Route>
      <Route path="/locales/*" element={<Menu />}></Route>
      <Route path="/mi-cuenta/*" element={<Menu />}></Route>
      <Route path="/facturacion/*" element={<Menu />}></Route>
      <Route path="/marketing/*" element={<Menu />}></Route>
      <Route path="/comisiones/*" element={<Menu />}></Route>
      <Route
        path="/roles/*"
        element={
          <ProtectedRoute
            accessKeyModule="7b43d2ce-908f-4d20-8f1d-b66c3c9c5d95"
            element={<Menu />}
          />
        }
      ></Route>
      <Route path="/proveedores/*" element={<Menu />}></Route>
      <Route path="/reportes/*" element={<Menu />}></Route>
      <Route path="/onboarding/*" element={<></>}></Route>
      <Route path="/recuperar/*" element={<></>}></Route>
      <Route path="/" element={<></>}></Route>
      <Route path="*" element={<Menu />}></Route>
    </Routes>
  );
};

export default ListContextRoutes;
