import { checkPlanStatus_service } from "../services/CheckPlanStatus.service";

export enum PlanStatusType {
  OK = 0,
  VALID = 1,
  GRACE_PERIOD = 2,
}

export type IPlanStatus = {
  type: PlanStatusType;
  daysRemaining: number;
  message: string;
};

export const checkPlanStatus = async () => {
  const response = await checkPlanStatus_service();

  const formattedResponse = {
    isSuccessful: response.isSuccessful,
    status: response.status,
    message: response.message,
    data: {
      type: response.data.type,
      daysRemaining: response.data.days,
      message: response.data.message,
    },
  };

  return formattedResponse;
};
