import React, { useEffect, useState } from "react";
import {
  ModalAcceptCancel as ModalComponent,
  //@ts-ignore
} from "@viuti/recursos";
import { ACCESS_DENIED, PLAN_LIMIT_REACHED } from "../../models/access";
import { IAccountInfo } from "../../models/interfaces/account";
import AccessDenied from "./components/AccessDenied/AccessDenied";
import PlanDenied from "./components/PlanDenied/PlanDenied";

const ModalAccessDenied = () => {
  const [isAccessModalVisible, setIsAccessModalVisible] = useState(false);
  const [requiredAccess, setRequiredAccess] = useState(null);
  const [modalType, setModalType] = useState<string>();
  const [fetchResponse, setFetchResponse] = useState<IResponse>(null);

  const accountInfo: IAccountInfo = JSON.parse(
    localStorage.getItem("account_info")
  );

  useEffect(() => {
    const updateModalType = (type) => {
      setIsAccessModalVisible(true);
      setModalType(type);
    };

    const handleAccessDenied = (event) => {
      const { accessId } = (event as CustomEvent).detail;
      setRequiredAccess(accessId);
      updateModalType(ACCESS_DENIED);
    };

    const handlePlanDenied = (event) => {
      const { accessId } = (event as CustomEvent).detail;
      setRequiredAccess(accessId);
      updateModalType(PLAN_LIMIT_REACHED);
    };

    window.addEventListener("access-denied", handleAccessDenied);
    window.addEventListener("plan-denied", handlePlanDenied);

    return () => {
      window.removeEventListener("access-denied", handleAccessDenied);
      window.removeEventListener("plan-denied", handlePlanDenied);
    };
  }, []);

  if (!accountInfo) {
    window.location.replace("/");
    return null;
  }

  // Extracción de la operación ternaria anidada
  const modalTitle =
    modalType === ACCESS_DENIED
      ? "Acceso denegado"
      : "Límite de plan alcanzado";

  return (
    <ModalComponent
      title={modalTitle}
      visibility={{
        isVisible: isAccessModalVisible,
        isSetVisible: setIsAccessModalVisible,
      }}
      actionButtonClose={() => {
        setIsAccessModalVisible(false);
        setFetchResponse(null);
      }}
      showButtons={{
        showButtonOne: false,
        showButtonTwo: false,
        showButtonClose: true,
      }}
      zIndex={1002}
    >
      {modalType === ACCESS_DENIED ? (
        <AccessDenied
          accountInfo={accountInfo}
          requiredAccess={requiredAccess}
          emailSentResponse={fetchResponse}
          setEmailSentResponse={setFetchResponse}
        />
      ) : (
        <PlanDenied accountInfo={accountInfo} />
      )}
    </ModalComponent>
  );
};

export default ModalAccessDenied;
