import {
  IDegradedPlanStatus,
  IDegradedPlanConfig,
} from "../models/interfaces/planStatus";

const STORAGE_KEY = "degraded_plan_notification";
const DEFAULT_CONFIG: IDegradedPlanConfig = {
  notificationFrequencyDays: 1,
};

export const getDegradedPlanStatus = (): IDegradedPlanStatus | null => {
  const storedStatus = localStorage.getItem(STORAGE_KEY);
  return storedStatus ? JSON.parse(storedStatus) : null;
};

export const shouldShowNotification = (
  config: IDegradedPlanConfig = DEFAULT_CONFIG
): boolean => {
  const status = getDegradedPlanStatus();

  if (!status) return true;

  const lastNotification = new Date(status.lastNotificationDate);
  const currentDate = new Date();

  if (lastNotification > currentDate) return false;

  const diffTime = currentDate.getTime() - lastNotification.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  return diffDays >= config.notificationFrequencyDays;
};

export const updateNotificationStatus = (): void => {
  const newStatus: IDegradedPlanStatus = {
    lastNotificationDate: new Date().toISOString(),
    notificationFrequencyDays: DEFAULT_CONFIG.notificationFrequencyDays,
    isNotified: true,
  };

  localStorage.setItem(STORAGE_KEY, JSON.stringify(newStatus));
};
