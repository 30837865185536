import { BrowserRouter as Router } from "react-router-dom";
import ListContextRoutes from "../routes/App.router";
import "./Layout.css";
import { useEffect } from "react";
import ModalPlanStatus from "../components/ModalPlanStatus/ModalPlanStatus";

const App = () => {
  /**
   * # This effect is used to toggle the layout display property.
   * # The empty string in the excludedRoutes array is the login route.
   */
  const excludedRoutes = ["", "onboarding", "recuperar"];

  useEffect(() => {
    const handleLocationChange = () => {
      const newPath = window.location.pathname;
      const mainElement = document.getElementsByTagName("main")[0];
      if (mainElement) {
        if (excludedRoutes.includes(newPath.split("/")[1])) {
          mainElement.style.display = "flex";
        } else {
          mainElement.style.display = "grid";
        }
      }
    };

    window.addEventListener("popstate", handleLocationChange);
    handleLocationChange();
    return () => window.removeEventListener("popstate", handleLocationChange);
    // eslint-disable-next-line
  }, []);

  return (
    <Router>
      <ListContextRoutes />
      <ModalPlanStatus />
    </Router>
  );
};

export default App;
