import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
//@ts-ignore
import styled from "./styles/MenuWrapper.module.css";

import type { IMenuWrapperProps } from "./interfaces/MenuWrapper";
import MenuBusinessSection from "../menuBusinessSection/MenuBusinessSection";
import MenuUserActionSection from "../menuUserActionSection/MenuUserActionSection";

import {
  CalendarIcon,
  CashRegisterStrokeIcon,
  SiteIcon,
  PeopleIcon,
} from "../../models/icons";

import FooterButton from "../FooterButton/FooterButton";
import ModalAccessDenied from "../ModalAccessDenied/ModalAccessDenied";

const MenuWrapper = (props: IMenuWrapperProps) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [footerContainer, setFooterContainer] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const footerContent = (
    <footer className={styled.navigation__footer}>
      <section className={styled.navigation__footer__content}>
        <FooterButton
          icon={CashRegisterStrokeIcon}
          label={"Cajas"}
          redirectTo={"/cajas"}
          accessId="cca23c7c-7798-43ca-a108-052677830169"
        />
        <FooterButton
          icon={CalendarIcon}
          label={"Reservas"}
          redirectTo={"/reservas"}
          accessId="632f5f6f-6bd5-4ae8-926f-42dfd7b8aa93"
        />
        <FooterButton
          icon={PeopleIcon}
          label={"Clientes"}
          redirectTo={"/clientes"}
          accessId="5a2518a6-cf5d-4ff5-ac29-50409ead893e"
        />
        <FooterButton
          icon={SiteIcon}
          label={"Estadísticas"}
          redirectTo={"/estadisticas"}
          accessId="081d50e6-c46f-44fd-897f-12a9db66d39e"
        />
      </section>
    </footer>
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const footerElement = document.getElementById("viuti-front-mainContent")
        ?.parentElement.parentElement;
      if (footerElement) {
        setFooterContainer(footerElement);
        clearInterval(intervalId); // Stop the search
      }
    }, 300); // Execute this effect every 300ms
    return () => {
      clearInterval(intervalId); // Remove the interval when the component is unmounted
    };
  }, []);

  return (
    <>
      <nav className={styled.menuWrapper__navegation}>
        <section className={styled.menuWrapper__navegation__section}>
          <MenuBusinessSection />
          <MenuUserActionSection />
        </section>
      </nav>
      <ModalAccessDenied />
      {screenWidth < 900 &&
        footerContainer &&
        ReactDOM.createPortal(footerContent, footerContainer)}
    </>
  );
};

export default MenuWrapper;
