import React from "react";
//@ts-ignore
import styled from "../style/Profile.module.css";

export const EmployeeHasNotPicture = ({ name, surname }) => {
  const nameFirstLetter = name[0];
  const surnameFirstLetter = surname[0];

  return (
    <div
      className={styled.avatarUser}
    >{`${nameFirstLetter}${surnameFirstLetter}`}</div>
  );
};

export const EmployeeHasPicture = ({ imgUrl, name, surname }) => {
  const [isImgError, setIsImgError] = React.useState(false);
  if (isImgError)
    return EmployeeHasNotPicture({
      name: name,
      surname: surname,
    });
  return (
    <img
      className={styled.profile__img}
      src={imgUrl}
      onError={() => {
        setIsImgError(true);
      }}
      alt="avatar"
    />
  );
};
