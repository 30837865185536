import React, { useEffect, useState } from "react";
// @ts-ignore
import styled from "./MenuUserActionSection.module.css";
import Profile from "../profile/Profile";
import { WhatsAppIcon } from "../../models/icons";
import { IAccountInfo } from "@Models/interfaces/account";

const MenuUserActionSection = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Función para generar el enlace de WhatsApp
  const generateWhatsAppLink = () => {
    const phoneNumber = "+51905453587";
    const account_info: IAccountInfo = JSON.parse(
      localStorage.getItem("account_info")
    );

    const employeeName = account_info.employee.name;
    const employeeLastName = account_info.employee.lastName;
    const companyName = account_info.lounge.name;

    const message = encodeURIComponent(
      `Hola, soy ${employeeName} ${employeeLastName} de la empresa ${companyName} y necesito ayuda por favor.`
    );
    return `https://wa.me/${phoneNumber}?text=${message}`;
  };

  return (
    <article className={styled.menuUserActionSection__article}>
      <button
        className={styled.whatsappButton}
        onClick={() => window.open(generateWhatsAppLink(), "_blank")}
      >
        <div className={styled.whatsappButtonContent}>
          <img
            src={WhatsAppIcon}
            alt="WhatsApp"
            className={styled.whatsappIcon}
          />
          <span>¿Necesitas ayuda?</span>
        </div>
      </button>
      <Profile width={screenWidth} />
    </article>
  );
};

export default MenuUserActionSection;
