import React, { useEffect, useState } from 'react';
//@ts-ignore
import styled from '../style/Profile.module.css';
import {
	EmployeeHasNotPicture,
	EmployeeHasPicture,
} from '../views/EmployeePicture';
import ValidateNavigation from '../../ValidateNavigation/ValidateNavigation';
import { IAccountInfo } from '../../../models/interfaces/account';
import { HiArrowRightOnRectangle, HiMiniStar, HiXMark } from 'react-icons/hi2';
import { ADMIN_NAVIGATION_OPTIONS, COMMON_NAVIGATION_OPTIONS } from '../consts';
// @ts-ignore
import { decodeToken, getUserPermissions } from '@viuti/recursos';
import { WhatsAppIcon } from '@Models/icons';

const UserMenuList = ({ handleToggleMenu }) => {
	const [navigationOptions, setNavigationOptions] = useState(
		COMMON_NAVIGATION_OPTIONS
	);

	const userPermissions = getUserPermissions();
	const account_info: IAccountInfo = JSON.parse(
		localStorage.getItem('account_info')
	);

	const handleLogOut = () => {
		localStorage.clear();
		sessionStorage.clear();
		window.location.href = '/';
	};

	// Función para generar el enlace de WhatsApp
	const generateWhatsAppLink = () => {
		const phoneNumber = '+51905453587';
		const message = encodeURIComponent(
			`Hola, soy ${account_info.employee.name} ${account_info.employee.lastName} de la empresa ${account_info.lounge.name} y necesito ayuda por favor.`
		);
		return `https://wa.me/${phoneNumber}?text=${message}`;
	};

	useEffect(() => {
		if (userPermissions?.account?.isAdmin) {
			setNavigationOptions(ADMIN_NAVIGATION_OPTIONS);
		} else {
			setNavigationOptions(COMMON_NAVIGATION_OPTIONS);
		}
	}, [userPermissions?.account?.isAdmin]);

	return (
		<>
			<section className={styled.navigation__content}>
				<header className={styled.profile__menuList__header}>
					<span className={styled.profile__basics__container}>
						{account_info.employee.profileImage ? (
							<EmployeeHasPicture
								imgUrl={account_info.employee.profileImage}
								name={account_info.employee.name}
								surname={account_info.employee.lastName}
							/>
						) : (
							<EmployeeHasNotPicture
								name={account_info.employee.name}
								surname={account_info.employee.lastName}
							/>
						)}
						<span className={styled.profile__basics}>
							<p className={styled.profile__alias}>
								{account_info.employee.alias}
							</p>
							<strong className={styled.profile__menuList__strongName}>
								{account_info.employee.name} {account_info.employee.lastName}
							</strong>
						</span>
					</span>
					<button
						className={styled.sidebar__close_button}
						onClick={handleToggleMenu}
					>
						<HiXMark size={18} color="#8C96A0" />
					</button>
				</header>
				<span className={styled.account__details}>
					<p className={styled.profile__email}>{account_info.employee.email}</p>
					<section className={styled.profile__employeeType}>
						<article>{account_info.role.name}</article>
						<article>{account_info.lounge.name}</article>
					</section>
				</span>
				<span className={styled.profile__planStatus}>
					<div className={styled.profile__planStatus__icon__container}>
						<span className={styled.profile__planStatus__icon}>
							<HiMiniStar size={8} color="#937cf4" />
						</span>
					</div>
					<p className={styled.profile__planStatus__message}>
						<b>Plan {account_info.plan.name}</b>
					</p>
				</span>
				<section className={styled.profile__options}>
					{navigationOptions.map((option) => {
						return (
							<React.Fragment key={option.id}>
								<hr className={styled.menu_list__divisor} />
								<ul className={styled.profile__options__list}>
									<p className={styled.profile__options__list_label}>
										{option.label}
									</p>
									{option.items.map((item) => {
										return (
											<li
												key={item.accessId}
												className={styled.profile__options__list_item}
											>
												<ValidateNavigation
													accessId={item.accessId}
													customClassName={styled.optionMenu}
													href={item.redirectTo}
													key={item.accessId}
												>
													<span className={styled.optionMenu__icon}>
														{item.icon}
													</span>
													<p>{item.name}</p>
												</ValidateNavigation>
											</li>
										);
									})}
								</ul>
							</React.Fragment>
						);
					})}

					{/* Sección de Ayuda */}
					<hr className={styled.menu_list__divisor} />
					<ul className={styled.profile__options__list}>
						<p className={styled.profile__options__list_label}>
							Centro de Ayuda
						</p>
						<li className={styled.profile__options__list_item}>
							<a
								href={generateWhatsAppLink()}
								target="_blank"
								rel="noopener noreferrer"
								className={styled.optionMenu}
							>
								<span className={styled.optionMenu__icon}>
									{/* <FaWhatsapp size={16} /> */}
									<img src={WhatsAppIcon} alt="WhatsApp" />
								</span>
								<p>Contactar por WhatsApp</p>
							</a>
						</li>
						{/* <li className={styled.profile__options__list_item}>
              <a
                href="mailto:soporte@tuempresa.com"
                className={styled.optionMenu}
              >
                <span className={styled.optionMenu__icon}>
                  <HiOutlineEnvelope size={16} />
                </span>
                <p>Enviar un correo</p>
              </a>
            </li> */}
					</ul>
				</section>
			</section>
			<div className={styled.profile__options__list}>
				<div className={styled.profile__options__list_item}>
					<a
						target="_blank"
						href={`https://academy.zymple.io/login?token=${localStorage.access_token}`}
						className={`${styled.optionMenu} ${styled.optionMenu__link_academy}`}
					>
						<p className={styled.optionMenu__label}>Prikly Academy</p>
					</a>
				</div>
				<div className={styled.profile__options__list_item}>
					<button
						className={`${styled.optionMenu} ${styled.logOut__button}`}
						onClick={handleLogOut}
					>
						<p>Cerrar sesión</p>
						<HiArrowRightOnRectangle size={16} />
					</button>
				</div>
			</div>
		</>
	);
};

export default UserMenuList;
