// @ts-ignore
import { getUserPermissions } from "@viuti/recursos";

export const validateAccess = (href: string, accessId: string) => {
  if (!accessId) return (window.location.href = href);

  const userPermissions = getUserPermissions();
  const permissions = userPermissions.accessKeyModules;
  const companyPlan = userPermissions.plan;
  const selectedAccess = permissions.find((p) => p.id === accessId);

  if (!selectedAccess) {
    const accessDeniedEvent = new CustomEvent("access-denied", {
      detail: { accessId },
    });
    window.dispatchEvent(accessDeniedEvent);
  } else if (
    selectedAccess.hierarchy > companyPlan.hierarchy ||
    (companyPlan.id === 1 && !companyPlan.isTrial)
  ) {
    const planDeniedEvent = new CustomEvent("plan-denied", {
      detail: { selectedAccess },
    });
    window.dispatchEvent(planDeniedEvent);
  } else {
    window.location.href = href;
  }
};
